$theme-colors: (
  "primary": #00ba88,
  "primary2": #ff782d,
  "dark1": #171c3d,
  "dark2": #62718f,
  "black": #000,
  "green": #7cc04b,
  "ass": #f6f8fb,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);
// font-family: 'Poppins', sans-serif;
$font-family-sans-serif: "Poppins", sans-serif;
// @import "@fancyapps/ui/dist/fancybox.css";
@import "~bootstrap/scss/bootstrap.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import url('https://fonts.googleapis.com/css2?family=Chewy&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
body {
  letter-spacing: 0.02em;
}
.mat-select-black {
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    color: #000000 !important;
  }
}
.mat-select-dark2 {
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    color: #94a2b3 !important;
  }
}

body {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #0056e9;
  }
  .MuiInput-underline:before {
    display: none !important;
  }
  .MuiInput-underline:after {
    display: none !important;
  }
  .MuiFormControl-marginNormal {
    margin: 0.5rem 0;
    padding: 0 1.5rem;
    width: 100%;
  }
  .MuiInputBase-input {
    font-weight: 500;
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;

    // #{$fontSize / 16}rem
  }
  .MuiIconButton-root {
    padding: 0;
    span {
      width: auto !important;
    }
  }
  .MuiTypography-body1 {
    font-weight: 500;
    font-size: 0.875rem;
    padding-left: 6px;
    color: #000000;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #0056e9;
  }
  .MuiSlider-track {
    height: 4px;
    border-radius: 4px;
  }
  .MuiSlider-rail {
    height: 4px;
    border-radius: 4px;
  }
  .MuiSlider-thumb {
    width: 26px;
    height: 26px;
    color: #fff;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25) !important;
    margin-top: -12px;
  }

  //  input placeholder color change
  input::placeholder {
    color: #62718F !important;
  }

  input{
    color:#171C3D !important;
    font-weight: 400;
  }

 
}

.btn-xxl {
  font-size: 1.5rem;
  padding: 1rem 3.8rem;
  @media (min-width: 991px) {
    // padding: 1.3rem 3.5rem;
    padding: 1.1rem 2rem;
  }
  @media (min-width: 1441px) {
    padding: 1.9rem 3.8rem;
  }
}

.btn {
  cursor: pointer;
  &:hover {
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
}

hr {
  border-color: #dde3ee;
}

img {
  max-width: 100%;
}

.listTable {
  thead {
    td {
      padding: 1.5rem 2.5rem;
      vertical-align: middle;
    }
  }
  tbody {
    td {
      padding: 1.25rem 2.5rem;
      vertical-align: middle;
    }
  }
}

.pageMainCard {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.form-control {
  height: 3rem;
  line-height: 3rem;
  padding: 0 2rem;
}
.btn-pad {
  padding: 0.7rem;
}
.btn-pad-cap {
  padding: 0.7rem 1.5rem;
}

// font-family: 'Poppins', sans-serif;
// font-family: 'Roboto', sans-serif;

@media (min-width: 1200px) {
  .container {
    max-width: 93%;
  }
}
@media (min-width: 1800px) {
  .container {
    max-width: 1620px;
  }
}

// @media (max-width: 1599px) {
//   :root {
//     font-size: 14px;
//   }
// }
// @media (max-width: 991px) {
//   :root {
//     font-size: 12px;
//   }
// }
body {
  // user-select: none;
  &.noScroll {
    overflow: hidden;
  }
}
.MuiBadge-badge {
  background-color: #ed686b !important;
}
* {
  outline: none !important;
}
a {
  transition: ease 0.3s;
  text-decoration: none !important;
  cursor: pointer;
}
svg {
  transition: ease 0.3s;
  // vertical-align: baseline;
}
button {
  transition: ease 0.3s;
  -webkit-appearance: none;
}

.bg-fade-primary {
  background-color: #f4f8ff;
}

.oft-cov {
  object-fit: cover;
}

.ff-roboto {
  font-family: "Roboto", sans-serif;
}
.ff-poppins {
  font-family: "Poppins", sans-serif;
}
.cp {
  cursor: pointer;
}

.text-h-dark {
  &:hover {
    color: #000 !important;
  }
}

select {
  -webkit-appearance: none;
  cursor: pointer;
  // background-image: url("assets/images/selectArrow.svg");
  background-position: 90% center;
  background-repeat: no-repeat;
}

@for $fw from 0 to 10 {
  .fw-#{$fw} {
    font-weight: $fw * 100 !important;
  }
}
@for $fade from 0 to 10 {
  .fade-#{$fade} {
    opacity: $fade / 10 !important;
  }
}
.iconBtnDark {
  color: #171c3d;
  &:hover {
    color: var(--bs-primary2);
  }
}
.iconBtnAss {
  color: #bfc6d2;
  &:hover {
    color: var(--bs-primary2);
  }
}

// ************ Font size  *************
$fontSizeLastValue: 50;
@for $fontSize from 0 through $fontSizeLastValue {
  .fs-#{$fontSize} {
    font-size: #{$fontSize / 16}rem !important;
  }
}
@each $deviceName, $screenWidth in $grid-breakpoints {
  @media (min-width: $screenWidth) {
    @for $fontSize from 0 through $fontSizeLastValue {
      .fs-#{$deviceName}-#{$fontSize} {
        font-size: #{$fontSize / 16}rem !important;
      }
    }
  }
}

.addPages {
  .fileUpload {
    width: 164px;
    height: auto;
    // box-shadow: 0px 3px 24px #dfdada;
    border-radius: 10px;
    text-align: center;
    background-color: #f2f7ff;
    border: 1px solid #bbd4ff;
    color: #0056e9;
    line-height: 1;
    padding: 1.5rem 0;
    cursor: pointer;
    transition: ease 0.3s;
    &:hover {
      background-color: #fff;
      box-shadow: 0px 3px 24px #dfdada;
    }
  }

  .label {
    width: 140px;
    text-align: right;
  }

  .inputBox {
    width: 319px;
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    input {
      border: 0;
    }
    span {
      width: 64px;
      text-align: center;
      line-height: 3rem;
    }
  }

  .customSelect {
    width: 319px;
    border-radius: 25px;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    padding: 0.4rem 1rem;
  }

  textarea {
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    resize: none;
  }

  // .bgBTN{
  //     padding: 0 3rem;
  //     line-height: 3rem;
  //     height: 3rem;
  // }

  .bgBTN {
    > span {
      display: flex;
      padding-left: 2rem;
      align-items: center;
      span {
        background-color: #1967eb;
        display: block;
        padding: 0;
        width: 3.5rem;
        height: 3rem;
        line-height: 3rem;
        margin-left: 2rem;
      }
    }
  }
  .NobgBtn {
    color: #666666;
    &:hover {
      color: #000;
    }
  }
}

.modalWarper {
  border-radius: 30px;
  width: 1087px;
  max-width: calc(100% - 60px);
  margin: 10vh auto 0;

  .card-header {
    padding: 20px 3rem;
  }
}

.dashboardOrderDetailsCard {
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .card {
    width: 435px;
    max-width: 100%;
    margin-left: auto;
    border-radius: 0;
    padding: 1.5rem 2rem;
    .card-header {
      .closeBtn {
        color: #bfc6d2;
        cursor: pointer;
        transition: ease 0.3s;

        &:hover {
          color: var(--bs-primary2);
        }
      }
    }

    .card-body {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    .order-drop-down {
      .MuiInputBase-input {
        // padding-left: 0.2rem;
        // background-image: url("./assets/cart.svg");
        // background-position: 20px center;
        // background-repeat: no-repeat;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #bfc6d2;
        border-radius: 12px;
      }
      .MuiInputBase-colorPrimary {
        color: #62718f;
      }
    }

    .order-drop-down2 {
      .MuiInputBase-input {
        padding: 7px 10px 7px 11px !important;
        font-size: 14px;
      }
    }
    .summaryBox {
      table {
        // tr {
        //   td {
        //     vertical-align: top;
        //     &:first-child {
        //       width: 72px;
        //     }
        //   }
        // }
        img {
          width: 70px;
          height: 70px;
          border-radius: 15px;
          display: block;
          object-fit: cover;
        }
        // .titleSection{
        //   display: flex;
        // }
      }
      .priceCalc {
        background-color: #f6f8fb;
        border-radius: 12px;
        padding: 10px 20px;
        .row {
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
        }
        span {
          border-radius: 25px;
          background-color: #e8f9dc;
          color: #7cc04b;
          font-size: 12px;
          font-weight: 500;
          padding: 8px 16px;
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.DashboardAddNewProductCard {
  // background-color: rgba(0, 0, 0, 0.6);

  background-color: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .card {
    // width: 690px;
    max-width: 100%;
    margin: auto;
    // border-radius: 15px;
    max-height: 80vh;
    .card-header {
      padding: 1.5rem 2rem;
      .closeBtn {
        color: #bfc6d2;
        cursor: pointer;
        transition: ease 0.3s;

        &:hover {
          color: var(--bs-primary2);
        }
      }
    }

    .card-body {
      padding: 1.5rem 2rem;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
      .row {
        > div {
          padding: 0.6rem 1rem;
        }
      }
      .label {
        margin-bottom: 10px;
        color: #171c3d;
        font-weight: 500;
      }
      .unit {
        position: relative;
        span {
          position: absolute;
          right: 1px;
          top: 1px;
          bottom: 1px;
          background-color: #f6f8fb;
          line-height: 3rem;
          width: 3rem;
          text-align: center;
          border-radius: 0 11px 11px 0;
        }
      }
      .order-drop-down {
        .MuiSelect-select {
          padding: 0;
        }
        .MuiOutlinedInput-root {
          line-height: 3rem;
        }
        .MuiInputBase-input {
          padding-left: 1.8rem;
          font-weight: 400;
          color: #62718f;
          font-size: 1rem;
          font-family: "Poppins", sans-serif;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #bfc6d2;
          border-radius: 12px;
          top: 0;
          border-width: 1px !important;
        }
        .MuiInputBase-colorPrimary {
          color: #62718f;
        }
        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #7cc04b !important;
        }
      }
      .order-drop-down2 {
        .MuiInputBase-input {
          padding: 7px 10px 7px 11px !important;
          font-size: 14px;
        }
      }

      .form-control {
        border: 1px solid #bfc6d2;
        box-shadow: none !important;
        border-radius: 12px;
        color: #62718f;
        &:focus {
          border-color: #7cc04b;
        }
      }
      .uploadImage {
        width: 100%;
        margin-bottom: 15px;
        .borderArea {
          border: 2px dashed #bfc6d2;
          padding: 20px;
          border-radius: 12px;
          text-align: center;
          p {
            color: #7e8ba6;
            margin: 0;
            padding-top: 0.5rem;
            span {
              color: #62718f;
              span {
                color: #7cc04b;
              }
            }
          }
        }
      }
      .addProductRadio {
        width: 100%;
        margin: 0;
        input:checked + div {
          background-color: #e8f9dc;
          i {
            border: 5px solid #7cc04b;
          }
        }
        div {
          border: 1px solid #bfc6d2;
          height: 3rem;
          border-radius: 12px;
          padding: 0 20px;
          cursor: pointer;
          i {
            border: 1.5px solid #bfc6d2;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #fff;
          }
          span {
            color: #62718f;
          }
        }
      }
      .btn {
        line-height: 3.6rem;
        border-radius: 100px;
        color: #fff;
      }
      textarea {
        line-height: 1.9rem !important;

        &::-webkit-scrollbar {
          width: 0px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .dashboardOrderDetailsCard {
    .card {
      padding: 12px 4px;
      max-width: 355px;
      .card-header {
        .h5 {
          font-size: 14px;
        }
        .closeBtn {
          svg {
            width: 36px;
            height: 36px;
          }
        }
      }
      .card-body {
        .h6 {
          font-size: 14px;
        }
      }
      .order-drop-down {
        .MuiInputBase-input {
          padding: 12.5px 10px 12.5px 52px;
          font-size: 14px;
        }
      }
      .order-drop-down2 {
        .MuiInputBase-input {
          padding: 7px 10px 7px 11px !important;
          font-size: 14px;
        }
      }
      .summaryBox {
        .priceCalc {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .DashboardAddNewProductCard {
    .card {
      // width: 335px;
      .card-header {
        padding: 15px 20px;
        .h5 {
          font-size: 14px;
        }
        .closeBtn {
          padding: 0;
          svg {
            width: 35px;
            height: 35px;
          }
        }
      }
      .card-body {
        padding: 15px 20px;
        .uploadImage {
          .borderArea {
            padding: 20px 10px;
            p {
              font-size: 12px !important;
            }
          }
        }
        .label {
          font-size: 14px;
        }
        .form-control {
          font-size: 13px;
        }
        .btn {
          line-height: 34px;
          font-size: 14px !important;
        }
      }
    }
  }
}
.errText {
  color: red !important;
  margin-bottom: 0 !important;
  margin-top: 2px;
}

.greenText {
  color: #7cc04b;
}

.htmlToolTipHeader {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.MuiPagination-root {
  ul {
    flex-direction: row !important;
    justify-content: center;
    li {
      width: auto !important;
      .Mui-selected {
        background-color: #7cc04b;
      }
    }
  }
}

.activeMenu {
  color: #7cc04b;
}

.cursor-pointer {
  cursor: pointer;
}

.rotate {
  transform: rotate(180deg);
}

.height_100 {
  height: 100%;
  width: 100%;
}

.disable-button {
  &:disabled {
    background: #eff1f4 !important;
    background-color: #eff1f4 !important;
    color: #a7b4c2 !important;
    cursor: not-allowed;
  }
}

.disable-button-b {
  &:disabled {
    color: #a7b4c2 !important;
  }
}

.activeInput {
  input {
    &:active,
    &:focus,
    &:focus-visible {
      border: 1px solid #7cc04b;
    }
  }
}

.f-14 {
  font-size: 14px !important;
}

.no-wrap {
  white-space: nowrap;
}

.recharts-tooltip-wrapper {
  z-index: 9000 !important;
}

.lineHeight0 {
  line-height: 0 !important;
}

.label-text {
  color: #62718f;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
}

.f-400 {
  font-weight: 400;
}

.sleetLogo {
  height: 53px;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
  /*Your new styles */
  height: 10px;
  width: 10px;
  color: red;
}